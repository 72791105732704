import * as React from "react"
import { fetchUtils, Admin, Resource, ListGuesser } from 'react-admin'
import PostIcon from "@material-ui/icons/Book"
import UserIcon from "@material-ui/icons/Group"
import TvIcon from "@material-ui/icons/Tv"

import { UserList } from "./users"
import { PostList, PostEdit, PostCreate } from "./posts"
import Dashboard from "./Dashboard"
import authProvider from "./authProvider"
import simpleRestProvider from "ra-data-simple-rest"

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json"})
    }
    options.headers.set("Authorization", "Token 519692972fd54b247a02f28cb9d0264c4be44add")
    return fetchUtils.fetchJson(url, options)
}

const App = () => (
    <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={simpleRestProvider("https://haleos.de/api/smt/billboard", fetchJson)}>
        <Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon} />
        <Resource name="users" list={UserList} icon={UserIcon} />
        <Resource name="sticks" list={ListGuesser} icon={TvIcon} />
    </Admin>
)

export default App