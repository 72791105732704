import axios from "axios"

export default {

    // called when the user attempts to log in
    login: async ({username, password}) => {
        const body = {
            username: username,
            password: password
        }
        try {
            const response = await axios.post("https://haleos.de/api/authentication/api-token-auth/", body)
            console.log(response)
            localStorage.setItem("token", response.data.token)
            return Promise.resolve()
        } catch (err) {
            return Promise.reject()
        }
    },

    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('token')
        return Promise.resolve()
    },

    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token')
            return Promise.reject()
        }
        return Promise.resolve()
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject()
    },

    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
}